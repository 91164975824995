<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Pay Service</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-4 col-sm-4 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-sm-4 form-group row">
                <div class="floating-form-group with-icon">
                  <Datepicker
                    v-model="date"
                    hideInputIcon
                    :clearable="false"
                    :enableTimePicker="false"
                    range="true"
                    format="yyyy-MM-dd"
                    placeholder="Select Date"
                    @update:modelValue="getList(0)"
                  >
                  </Datepicker>
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword || date"
                  >Reset</a
                >
              </div>
            </div>

            <ul class="nav nav-pills mt-3" role="tablist">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-1"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  :class="{ active: currentTab == 'PENDING' }"
                  v-on:click="setStatus('PENDING')"
                >
                  <span class=""> Pending</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="true"
                  :class="{ active: currentTab == 'UTR_SUBMITTED_PENDING' }"
                  v-on:click="setStatus('UTR_SUBMITTED_PENDING')"
                >
                  <span class=""> UTR Submitted Pending</span></a
                >
              </li>

              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="true"
                  :class="{ active: currentTab == 'UTR_SUBMITTED_APPROVED' }"
                  v-on:click="setStatus('UTR_SUBMITTED_APPROVED')"
                >
                  <span class=""> UTR Submitted Approved</span></a
                >
              </li>
            </ul>

            <div class="card border mb-0 mt-3 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Company Name</th>
                          <th>User Name</th>
                          <th>Order ID</th>
                          <th>Amount</th>
                          <th v-if="this.currentTab != 'PENDING'">UTR Number</th>
                          <th v-if="currentTab == 'UTR_SUBMITTED_PENDING'">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                          </td>
                          <td v-if="data.buyer_id">{{ data.buyer.company_name }}</td>
                          <td v-else>{{ data.seller.company_name }}</td>
                          <td v-if="data.buyer_id">
                            {{ data.buyer.owner.first_name }}
                            {{ data.buyer.owner.last_name }}
                          </td>
                          <td v-else>
                            {{ data.seller.owner.first_name }}
                            {{ data.seller.owner.last_name }}
                          </td>
                          <td>
                            {{ data.order.order_no }}
                          </td>
                          <td>{{ data.amount }}</td>
                          <td v-if="this.currentTab != 'PENDING'">
                            {{ data.transaction.utr_number }}
                          </td>
                          <td v-if="currentTab == 'UTR_SUBMITTED_PENDING'">
                            <a
                              class="approve-btn btn-style cursor-pointer"
                              v-on:click="showPopup('RESOLVED', data)"
                              >Accept</a
                            >
                            <a
                              class="reject-btn btn-style cursor-pointer"
                              v-on:click="showPopup('REJECTED', data)"
                              >Reject</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
    <TextModal ref="statusModal" class="modal-small">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body text-center px-md-5">
          <h5 class="modal-title font-22 mb-4" v-if="status == 'RESOLVED'">Resolve</h5>
          <h5 class="modal-title font-22 mb-4" v-if="status == 'REJECTED'">Reject</h5>

          <p class="font-18" v-if="status == 'RESOLVED'">
            Are you sure <br />
            you want to resolve?
          </p>
          <p class="font-18" v-if="status == 'REJECTED'">
            Are you sure <br />
            you want to reject?
          </p>
        </div>
        <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
          <div class="row">
            <div class="col-md-6">
              <button
                type="button"
                class="fill-btn btn-style cursor-pointer mb-3"
                id="status-button"
                style="min-width: 115px"
                v-on:click="updateStatus()"
              >
                Yes
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                class="reject-btn btn-style cursor-pointer mb-3"
                style="min-width: 115px"
                v-on:click="$refs.statusModal.closeModal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </TextModal>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import TextModal from "@/components/TextModal.vue";
export default {
  name: "ServiceComponent",
  components: {
    ErrorComponent,
    TextModal,
    Pagination,
    Datepicker,
  },
  data() {
    return {
      date: null,
      loader: true,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
      currentTab: "PENDING",
      id: "",
      status: "",
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    showPopup(status, data) {
      this.id = data.id;
      this.status = status;
      this.$refs.statusModal.showModal();
    },
    setStatus(type) {
      this.currentTab = type;
      this.getList(0);
    },
    updateStatus() {
      var obj = {
        id: this.id,
        status: this.status,
      };
      this.$api
        .postAPI({
          _action: "update-service-status",
          _body: obj,
          _buttonId: "status-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.statusModal.closeModal();
          this.id = "";
          this.status = "";
          this.list = [];
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.status = this.currentTab;
      if (this.date) {
        this.searchObj.from_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.searchObj.to_date = moment(this.date[1]).format("YYYY-MM-DD");
      }
      this.$api
        .getAPI({
          _action: "get-services",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No pay services available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.date = null;
      this.getList(0);
    },
  },
};
</script>

