<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"><span class="font-24 mr-2"><img
                    src="/images/back-arrow.svg" width="18" /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }} Comission
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label> Min Value</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="min_value" class="form-control" placeholder="Min Value"
                              v-model="details.min_value" rules="required:min value|decimal|minValue:0"
                              :validateOnInput="true" />
                            <ErrorMessage name="min_value" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label> Max Value</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="max_value" class="form-control" placeholder="Max Value"
                              v-model="details.max_value" rules="required:max value|decimal|minValue:0"
                              :validateOnInput="true" />
                            <ErrorMessage name="max_value" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label> Buyer Comission Percentage</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="buyer_comission_percentage" class="form-control"
                              placeholder="Buyer Comission Percentage" v-model="details.buyer_comission_percentage"
                              rules="required:buyer comission percentage|decimal" :validateOnInput="true" />
                            <ErrorMessage name="buyer_comission_percentage" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label> Seller Comission Percentage</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="seller_comission_percentage" class="form-control"
                              placeholder="Seller Comission Percentage" v-model="details.seller_comission_percentage"
                              rules="required:seller comission percentage|decimal" :validateOnInput="true" />
                            <ErrorMessage name="seller_comission_percentage" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 mr-3">
                            Save
                          </button>
                          <button type="button" class="btn cancel-btn cursor-pointer" v-on:click="$router.go(-1)" id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddCMS",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      details: {
        type: "",
        title: "",
        description: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/get-comission-info/" + this.id,
        })
        .then((res) => {
          this.details = res;
          this.details.description = "";
        })
        .catch(() => { });
    },
    save() {
      this.$api
        .postAPI({
          _action: "/set-comission",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$router.push("/comission");
          }
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped>
.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
  height: 50px !important;
}
</style>