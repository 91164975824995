<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Sub Category</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <Form @submit="signup" class="form-style floating-form">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="mb-3">
                                <div class="floating-form-group">
                                  <label for="name">Name</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    class="form-control"
                                    rules="required:Name"
                                    :class="detail.name ? 'filled' : ''"
                                    v-model="detail.name"
                                  />
                                  <ErrorMessage name="name" class="validation-msg mt-2" />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-sm-6" style="margin-top: 25px;">
                              <Multiselect
                                mode="tags"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                v-model="list"
                                placeholder="Select Composition"
                                :options="compositionlist"
                              />
                            </div>
                            <div class="col-lg-12 col-sm-12 txt-right form-group">
                              <a
                                class="fill-btn cursor-pointer"
                                style=""
                                v-on:click="add()"
                                >+ Add Values</a
                              >
                            </div>

                            <template v-for="(item, i) in values" :key="i">
                              <div class="col-md-6">
                                <div class="mb-3">
                                  <div class="floating-form-group">
                                    <Field
                                      type="text"
                                      :id="'value_' + i"
                                      :name="'value_' + i"
                                      class="form-control"
                                      placeholder="Value"
                                      :rules="i == 0 ? 'required:Value' : ''"
                                      :class="item.value ? 'filled' : ''"
                                      v-model="item.value"
                                    />
                                    <ErrorMessage
                                      :name="'value_' + i"
                                      class="validation-msg mt-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div class="col-md-12 mt-3 text-center">
                              <button class="btn btn-brand-1" id="signup-button">
                                Save
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";
import router from "../../router";
export default {
  name: "AddSubYarn",
  components: { Form, Field, ErrorMessage, Multiselect },
  data() {
    return {
      detail: {
        parent_id: this.$route.params.parent_id,
      },
      list: [],
      uuid: this.$route.params.uuid,
      values: [
        {
          value: "",
        },
      ],
    };
  },
  mounted() {
    if (this.uuid) {
      this.getDetail();
    } else {
      if (this.detail.parent_id) {
        this.getCompositionList();
      }
    }
    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getCompositionList() {
      this.$api
        .getAPI({
          _action: "/composition-list",
          _body: { yarn_id: this.detail.parent_id },
        })
        .then((res) => {
          this.compositionlist = res;
          if(this.detail && this.detail.list){
            this.list = this.detail.list;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/yarn/detail/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.result;
          this.parent_id = this.detail.parent_id;
          this.getCompositionList();
          this.values = this.detail.values;
          if (this.values.length == 0) {
            this.add();
          }
        })
        .catch(() => {});
    },
    add() {
      this.values.push({
        value: "",
      });
    },
    remove(i) {
      this.values.splice(0, i);
    },
    signup() {
      this.detail.values = this.values;
      this.detail.list = this.list;
      this.$api
        .postAPI({
          _action: "sub-yarn-set",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          router.push("/sub-category/" + this.detail.parent_id);
        })
        .catch(() => {});
    },
  },
};
</script>

