<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">Save Setting</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <i
                              class="fa fa-envelope mr-1"
                              aria-hidden="true"
                            ></i>
                            <label>Email</label>
                            <Field
                              type="text"
                              class="form-control"
                              name="Username"
                              placeholder="Email"
                              v-model="details.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="Username" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Contact Number</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="phone_number"
                              class="form-control"
                              placeholder="Contact Number"
                              v-model="details.phone_number"
                              rules="required:phone number|order:phone number"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="phone_number"
                              class="text-danger"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Auto Cancel Bid Time (In Mins.)</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="auto_cancel_bid_time"
                              class="form-control"
                              placeholder="Auto Cancel Bid Time"
                              v-model="details.auto_cancel_bid"
                              rules="required:auto cancel bid time|order:auto cancel bid time"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="auto_cancel_bid_time"
                              class="text-danger"
                            />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 mr-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="btn cancel-btn cursor-pointer"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "SettingComponent",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      details: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/get-setting",
        })
        .then((res) => {
          this.details = res.result;
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "/set-setting",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.getDetail();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

