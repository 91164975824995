<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row col-lg-12 col-sm-12 my-auto">
          <h4 class="mb-0 lg-bld">Dashboard</h4>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <div class="card border mb-0 radius-15">
            <div
              class="card-body review-page"
              style="margin-top: 20px; margin-bottom: -1px"
            >
              <div class="row">
                <div class="col-lg-3 col-md-1 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div class="card-body review-page">
                        <div
                          style="cursor: pointer"
                          v-on:click="$router.push('/buyer')"
                        >
                          <p>Total Active Buyer : {{ countObj.buyer }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div class="card-body review-page">
                        <div
                          style="cursor: pointer"
                          v-on:click="$router.push('/seller')"
                        >
                          <p>Total Active Seller :{{ countObj.seller }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div class="card-body review-page">
                        <div
                          style="cursor: pointer"
                          v-on:click="$router.push('/dealer')"
                        >
                          <p>Total Active Dealer :{{ countObj.dealer }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div class="card-body review-page">
                        <div
                          style="cursor: pointer"
                          v-on:click="$router.push('/booking')"
                        >
                          <p>Total Booking :{{ countObj.booking }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div class="card-body review-page">
                        <div
                          style="cursor: pointer"
                          v-on:click="$router.push('/service')"
                        >
                          <p>Platform Turnover: ₹ {{ countObj.turnover }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "DashboardComponent",
  components: {},
  data() {
    return {
      countObj: {
        buyer: 0,
        seller: 0,
        dealer: 0,
        all_trade: 0,
        active_trade: 0,
        total_service: 0,
        pending_service: 0,
        submitted_service: 0,
        booking: 0,
        insurance: 0,
        turnover:0
      },
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getCount() {
      this.$api
        .getAPI({
          _action: "/dashboard",
        })
        .then((res) => {
          this.countObj = res;
        })
        .catch(() => {});
    },
  },
};
</script>

