<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Booking</h4>
            </div>
          </div>
        </div>

        <ul class="nav nav-pills mt-3" role="tablist">
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link"
              id="tab-1"
              data-toggle="tab"
              href="#tab_1"
              role="tab"
              aria-controls="tab_1"
              aria-selected="true"
              :class="{ active: currentTab == 'BASIC_DETAILS' }"
              v-on:click="getDetail('BASIC_DETAILS')"
            >
              <span class="text-dark"> Booking Detail</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link"
              id="tab-2"
              data-toggle="tab"
              href="#tab_2"
              role="tab"
              aria-controls="tab_2"
              aria-selected="true"
              :class="{ active: currentTab == 'ORDER' }"
              v-on:click="getList(0)"
            >
              <span class="text-dark"> Orders</span></a
            >
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane mt-3"
            id="tab_1"
            role="tabpanel"
            aria-labelledby="tab-1"
            :class="{ active: currentTab == 'BASIC_DETAILS' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="row ml-2"><h3>Booking Info</h3></div>
                  <div class="row">
                    <div class="col-lg-3"><label>Booking No.</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.booking_no }}</span>
                    </div>
                    <div class="col-lg-3"><label>Seller</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.seller.company_name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Buyer</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.buyer.company_name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Product</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.product.name }}</span>
                    </div>
                    <div class="col-lg-3"><label>Quantity</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.quantity }} (Kg)</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Buy Rate</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.buy_rate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane mt-3"
            id="tab_2"
            role="tabpanel"
            aria-labelledby="tab-2"
            :class="{ active: currentTab == 'ORDER' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 form-group row">
                      <select
                        class="form-control text-secondary"
                        v-model="searchObj.status"
                        @change="getList(0)"
                      >
                        <option value="">Select Status</option>
                        <option value="CREATED">Create</option>
                        <option value="LOADING">Loading</option>
                        <option value="VEHICLE_PLACED">vehicle Placed</option>
                        <option value="DISPATCHED">Dispatched</option>
                        <option value="UNLOADING">UnLoading</option>
                        <option value="ORDER_APPROVED">Order Approved</option>
                      </select>
                    </div>
                    <div class="col-lg-3 col-sm-6 form-group row ml-2">
                      <select
                        class="form-control text-secondary"
                        v-model="searchObj.payment_status"
                        @change="getList(0)"
                      >
                        <option value="">Select Payment Status</option>
                        <option value="PENDING">Pending</option>
                        <option value="UTR_SUBMITTED">UTR submitted</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Raised</option>
                      </select>
                    </div>
                    <div class="col-lg-6 col-sm-6 form-group row ml-2">
                      <a
                        class="fill-btn btn-style cursor-pointer"
                        v-on:click="resetFilter"
                        v-show="searchObj.status"
                        >Reset</a
                      >
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Order No.</th>
                          <th>Booking No.</th>
                          <th>Dispatch Date</th>
                          <th>Payment Status</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="list.length > 0">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td
                            v-on:click="
                              $router.push('/view-order/' + data.uuid)
                            "
                            class="hover-td"
                          >
                            {{ data.order_no }}
                          </td>
                          <td
                            v-on:click="
                              $router.push(
                                '/view-booking/' + data.booking.uuid
                              )
                            "
                            class="hover-td"
                          >
                            {{ data.booking.booking_no }}
                          </td>
                          <td>
                            {{
                              $helperService.getFormatedDate(data.dispatch_at)
                            }}
                          </td>
                          <td>{{ data.payment_status }}</td>
                          <td>{{ data.status }}</td>
                          <td>
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/view-order/' + data.uuid)
                              "
                              >View</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="row mx-0 mb-4">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination
                            @page-change="pageChange"
                            @items-per-page-change="itemsPerPageChange"
                            ref="listPagination"
                          />
                        </div>
                      </div>
                    </div>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "ViewBooking",
  components: { ErrorComponent, Pagination },
  data() {
    return {
      uuid: this.$route.params.id,
      details: {
        booking_no: "",
        dealer: {},
        product: {},
        buyer: {},
        seller: {},
        video: "",
      },
      currentTab: "BASIC_DETAILS",
      loader: false,
      searchObj: {
        keyword: "",
        status: "",
        payment_status: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;
    if (this.uuid) {
      this.getDetail("BASIC_DETAILS");
    }
  },
  methods: {
    getDetail(tab) {
      this.currentTab = tab;
      this.list = [];
      this.$api
        .getAPI({
          _action: "/booking/" + this.uuid,
        })
        .then((res) => {
          this.details = res.info;
          if (!this.details.video) {
            this.details.video = "";
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.currentTab = "ORDER";
      this.list = [];
      this.searchObj.offset = page;
      // this.$refs.errorComponent.updateLoader(true);

      this.$api
        .getAPI({
          _action: "order/list/" + this.details.uuid,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;

          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No orders available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.searchObj.status = "";
      this.getList(0);
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
}
</style>