import {
  createWebHistory,
  createRouter
} from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import MyProfile from "@/views/Onboarding/my-profile.vue";
import Product from "@/views/Products/products.vue";
import RequestedProduct from "@/views/Products/requested-products.vue";
import AddProduct from "@/views/Products/add-products.vue";
import Yarn from "@/views/Yarn/yarn.vue";
import SubYarn from "@/views/Yarn/subYarn.vue";
import AddYarn from "@/views/Yarn/add-yarn.vue";
import AddSubYarn from "@/views/Yarn/add-sub-yarn.vue";
import Composition from "@/views/Composition/composition.vue";
import AddComposition from "@/views/Composition/add-composition.vue";

import Setting from "@/views/Setting/setting.vue";
import Seller from "@/views/User/seller.vue";
import Importer from "@/views/User/importer.vue";
import Buyer from "@/views/User/buyer.vue";
import Dealer from "@/views/User/dealer.vue";
import EditSeller from "@/views/User/editSeller.vue";
import EditCompanyDetails from "@/views/User/editCompanyDetails.vue";
import EditBuyer from "@/views/User/editBuyer.vue";
import ViewDealer from "@/views/User/editDealer.vue";
import EditDealer from "@/views/User/addDealer.vue";
import AddDealer from "@/views/User/addDealer.vue";
import AddUser from "@/views/User/addUser.vue";
import Support from "@/views/Setting/support.vue";
import Notification from "@/views/Setting/notification.vue";
import Dashboard from "@/views/Setting/dashboard.vue";
import Booking from "@/views/Booking/booking.vue";
import ViewBooking from "@/views/Booking/viewBooking.vue";
import ViewOrder from "@/views/Booking/viewOrder.vue";
import Menu from "@/components/menu.vue";
import MainView from "@/views/main.vue";
import Service from "@/views/Setting/service.vue";

import CMS from "@/views/CMS/cms.vue";
import AddCMS from "@/views/CMS/addCms.vue";
import EditCMS from "@/views/CMS/addCms.vue";


import Comission from "@/views/Comission/comission.vue";
import AddComission from "@/views/Comission/addComission.vue";
import EditComission from "@/views/Comission/addComission.vue";


import Package from "@/views/Package/package.vue";
import AddPackage from "@/views/Package/addPackage.vue";
import EditPackage from "@/views/Package/addPackage.vue";

const routes = [{
    path: "",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      //===========My profile========
      {
        path: "/my-profile",
        name: "MyProfile",
        component: MyProfile,
      },
      // =============== Product ===============
      {
        path: "/product",
        name: "Product",
        component: Product,
      },

      {
        path: "/requested-product",
        name: "RequestedProduct",
        component: RequestedProduct,
      },

      {
        path: "/add-product",
        name: "Add Product",
        component: AddProduct,
      },
      {
        path: "/edit-product/:id",
        name: "Edit Products",
        component: AddProduct,
      },

      // =============== Yarn ===============
      {
        path: "/category",
        name: "Yarn",
        component: Yarn,
      },

      {
        path: "/sub-category/:id",
        name: "ParentYarn",
        component: SubYarn,
      },

      {
        path: "/add-category",
        name: "Add Yarn",
        component: AddYarn,
      },

      {
        path: "/add-sub-category/:parent_id",
        name: "Add SubYarn",
        component: AddSubYarn,
      },

      {
        path: "/edit-sub-category/:uuid",
        name: "Edit SubYarn",
        component: AddSubYarn,
      },

      {
        path: "/edit-category/:uuid",
        name: "Edit Yarn",
        component: AddYarn,
      },
      // Composition
      // =============== Yarn ===============
      {
        path: "/composition",
        name: "Composition",
        component: Composition,
      },

      {
        path: "/add-composition",
        name: "Add Composition",
        component: AddComposition,
      },

      {
        path: "/edit-composition/:id",
        name: "Edit Composition",
        component: AddComposition,
      },

      // User

      {
        path: "/seller",
        name: "Seller",
        component: Seller,
      },
      {
        path: "/importer",
        name: "Importer",
        component: Importer,
      },
      {
        path: "/buyer",
        name: "Buyer",
        component: Buyer,
      },
      {
        path: "/dealer",
        name: "Dealer",
        component: Dealer,
      },

      {
        path: "/edit-seller/:id",
        name: "EditSeller",
        component: EditSeller,
      },
      {
        path: "/edit-buyer/:id",
        name: "EditBuyer",
        component: EditBuyer,
      },
      {
        path: "/view-dealer/:id",
        name: "ViewDealer",
        component: ViewDealer,
      },

      {
        path: "/edit-dealer/:id",
        name: "EditDealer",
        component: EditDealer,
      },

      {
        path: "/add-dealer",
        name: "AddDealer",
        component: AddDealer,
      },

      {
        path: "/add-user/:type",
        name: "AddUser",
        component: AddUser,
      },


      {
        path: "/setting",
        name: "Setting",
        component: Setting,
      },

      {
        path: "/support",
        name: "Support",
        component: Support,
      },



      {
        path: "/menu",
        name: "Menu",
        component: Menu,
      },




      {
        path: "/booking",
        name: "Booking",
        component: Booking,
      },

      {
        path: "/view-booking/:id",
        name: "ViewBooking",
        component: ViewBooking,
      },

      {
        path: "/view-order/:id",
        name: "ViewOrder",
        component: ViewOrder,
      },
      {
        path: "/service",
        name: "Service",
        component: Service,
      },

      {
        path: "/cms",
        name: "CMS",
        component: CMS,
      },
      {
        path: "/add-cms",
        name: "AddCMS",
        component: AddCMS,
      },
      {
        path: "/edit-cms/:id",
        name: "EditCMS",
        component: EditCMS,
      },

      {
        path: "/comission",
        name: "Comission",
        component: Comission,
      },
      {
        path: "/add-comission",
        name: "AddComission",
        component: AddComission,
      },
      {
        path: "/edit-comission/:id",
        name: "EditComission",
        component: EditComission,
      },

      {
        path: "/package",
        name: "Package",
        component: Package,
      },
      {
        path: "/add-package",
        name: "AddPackage",
        component: AddPackage,
      },
      {
        path: "/edit-package/:id",
        name: "EditPackage",
        component: EditPackage,
      },

      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },

      {
        path: "/notification",
        name: "Notification",
        component: Notification,
      },


      {
        path: "/view-company/:id",
        name: "View Company Info",
        component: EditCompanyDetails,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: {
          nextUrl: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      if (localStorage.getItem("token") != null) {
        next({
          path: "/my-profile",
          params: {
            nextUrl: to.fullPath,
          },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;