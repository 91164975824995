<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <h5 class="modal-title" v-if="obj.user_type == 'SELLER'">Edit Seller Info</h5>
          <h5 class="modal-title" v-if="obj.user_type == 'BUYER'">Edit Buyer Info</h5>
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <Multiselect
                  mode="tags"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="setData.products"
                  placeholder="Select Product"
                  :options="productList"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            type="button"
            class="fill-btn"
            id="delete-button"
            :disabled="showLoader"
            style="width: 18%; border-radius: 5px"
            v-on:click="save()"
          >
            <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>Yes
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            style="width: 18%; border-radius: 5px"
            v-on:click="closeModal"
          >
            No
          </button>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
export default {
  name: "SetCommodities",
  components: {
    Multiselect,
  },
  data() {
    return {
      isShowModal: false,
      showLoader: false,
      productList: [],
      user_type: "",
      obj: {
        id: "",
        user_type: "",
      },
      setData: {
        products: [],
        user_type: "",
      },
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    showModal(obj) {
      this.obj = obj;
      this.user_type = obj.user_type;
      this.isShowModal = true;
      this.getProductList();
      this.getDetail();
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
    },
    save() {
      this.setData.user_type = this.obj.user_type;
      console.log();
      this.$api
        .putAPI({
          _action: "user/commodities/" + this.obj.id,
          _body: this.setData,
          _buttonId: "status-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.closeModal();
        })
        .catch(() => {});
    },

    getProductList() {
      this.$api
        .getAPI({
          _action: "product/list",
        })
        .then((res) => {
          this.productList = res.list;
        })
        .catch(() => {});
    },

    removeLoader() {
      this.showLoader = false;
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "user/details/" + this.obj.id + "/" + this.obj.user_type,
        })
        .then((res) => {
          this.data = res.result.products;
          // if (this.setData.products) {
          //   for (var i = 0; i < this.obj.products.length; i++) {
          //     this.setData.products.push(this.obj.products[i].product_id);
          //   }
          // }
        })
        .catch(() => {});
    },
  },
};
</script>
