<template>
    <div>
        <OuterHeader />
        <main role="main">
            <div id="main-wrapper" class="login-wrap pt-0">
                <div class="container-fluid h-100">
                    <div class="row justify-content-md-center align-items-center min-vh-100 bg-white">
                        <div class="col-md-6 bg-white">
                            <div class="row justify-content-center">
                                <div class="col-lg-6">
                                    <div class="px-lg-4 px-0 py-3 card shadow">
                                        <div class="card-header bg-white rounded-10 text-center border-0">
                                            <img src="/images/yarnmart-Logo.svg" width="300" height="60"
                                                class="img-fluid mb-3" />
                                        </div>
                                        <div class="">
                                            <Form @submit="login">
                                                <div class="text-center">
                                                    <label style="color: black; font-size: 44px">Login</label>
                                                </div>
                                                <div class="form-group">
                                                    <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                                                    <label>Email</label>
                                                    <Field type="text" class="form-control" name="Username"
                                                        placeholder="Email" v-model="details.email"
                                                        rules="required:email|email" :validateOnInput="true" />
                                                    <ErrorMessage name="Username" class="text-danger" />
                                                </div>
                                                <div class="form-group">
                                                    <i class="fa fa-lock mr-1" aria-hidden="true"></i>
                                                    <label>Password</label>
                                                    <Field type="password" class="form-control" name="_password"
                                                        placeholder="Password" v-model="details.password"
                                                        rules="required:password" :validateOnInput="true" />
                                                    <ErrorMessage name="_password" class="text-danger" />
                                                </div>

                                                <div class="btn-group mt-3 w-100">
                                                    <button class="btn btn-brand-1 btn-block mb-3" id="login-button">Log In</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import router from "../../router";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "LoginComponent",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            details: {
                email: "",
                password: "",
            },
        };
    },
    mounted() {
    },
    methods: {
        login() {
            this.$api
                .postAPI({
                    _action: "/login-user",
                    _body: this.details,
                    _buttonId: "login-button",
                })
                .then((res) => {
                    if (res && res.access_token) {
                        localStorage.setItem("token", res.access_token);
                        this.register();
                    }
                })
                .catch(() => { });
        },
        register() {
            let subscription = localStorage.getItem("subscription");
            console.log("subscription " + subscription);
            if (subscription) {
                this.$api
                    .postAPI({
                        _action: "register-device",
                        _body: {
                            token: subscription,
                            token_type: "WEB",
                        },
                    })
                    .then(() => {
                        this.getLoginUserDetail();
                    })
                    .catch((e) => {
                        this.$noty.error("" + e.message, {
                            timeout: 2000,
                            layout: "topCenter",
                            theme: "semanticui",
                        });
                    });
            } else {
                this.getLoginUserDetail();
            }
        },
        getLoginUserDetail() {
            this.$api
                .getAPI({
                    _action: "/me",
                })
                .then((res) => {
                    if (document.getElementById("loginuser-image")) {
                        document.getElementById("loginuser-image").src =
                            res.result.profile_image;
                    }
                    router.push("/my-profile");
                })
                .catch(() => {
                    localStorage.removeItem("token");
                });
        },
    },
};
</script>

