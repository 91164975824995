<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Comission</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(0)" placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter" v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                <a class="fill-btn cursor-pointer"
                  v-on:click="$router.push('/add-comission')">+ Add</a>
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Min Value</th>
                          <th>Max Value</th>
                          <th>Buyer Comission Percentage</th>
                          <th>Seller Comission Percentage</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>{{ data.min_value }}</td>
                          <td>{{ data.max_value }}</td>
                          <td>{{ data.buyer_comission_percentage }} %</td>
                          <td>{{ data.seller_comission_percentage }} %</td>

                          <td>
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="
                              $router.push('/edit-comission/' + data.id)
                              ">Edit</a>
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="showDeletePopup(data)">Delete</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
export default {
  name: "ComissionComponent",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      uuid: this.$route.params.id,
      loader: true,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
      product_id: 0,
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "get-comission-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.product_id = res.product_id;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No comission available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Comission",
        "Are you sure you want to delete ?",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .getAPI({
          _action: "/delete-comission/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>

