<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }} Product Field
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label> Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Title"
                              v-model="details.title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>
                              Select Type
                              <span class="text-danger">*</span></label
                            >
                            <Field
                              as="select"
                              name="type"
                              class="form-control text-secondary"
                              rules="required:type,true"
                              v-model="details.type"
                            >
                              <option value="">Select Type</option>
                              <option value="ABOUT">About Us</option>
                              <option value="TERM">Terms & Conditions</option>
                              <option value="POLICY">Privacy Policy</option>
                            </Field>
                          </div>
                          <ErrorMessage name="type" class="text-danger" />
                        </div>

                        <div class="col-lg-12 col-sm-6">
                          <vue-editor
                            v-model="details.description"
                            name="description"
                          ></vue-editor>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
  height: 50px !important;
}
</style>
  <script>
import { Form, Field, ErrorMessage } from "vee-validate";
// import { VueEditor } from "vue2-editor";
export default {
  name: "AddCMS",
  components: {
    Form,
    Field,
    ErrorMessage,
    // VueEditor
  },
  data() {
    return {
      id: this.$route.params.id,
      details: {
        type: "",
        title: "",
        description: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/get-cms-info/" + this.id,
        })
        .then((res) => {
          this.details = res;
          this.details.description = "";
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "/set-cms",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$router.push("/cms");
          }
        })
        .catch(() => {});
    },
  },
};
</script>