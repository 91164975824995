<template>
  <div class="wrapper">
    <Menu />
    <router-view></router-view>
  </div>
</template>

<script>
import Menu from "@/components/menu";
export default {
  name: "MainComponent",
  components: {
    Menu,
  },
  methods: {
    callMethod() {
      Menu.methods.getLoginUserDetail();
    },
  },
};
</script>

