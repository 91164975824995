<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Bookings</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-4 col-sm-4 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-sm-4 form-group row">
                <div class="floating-form-group with-icon">
                  <Datepicker
                    v-model="date"
                    hideInputIcon
                    :clearable="false"
                    :enableTimePicker="false"
                    range="true"
                    format="yyyy-MM-dd"
                    placeholder="Select Date"
                    @update:modelValue="getList(0)"
                  ></Datepicker>
                </div>

                <div class="col-lg-4 col-sm-4 txt-right form-group">
                  <a
                    class="fill-btn btn-style cursor-pointer ml-3"
                    v-on:click="getList(0)"
                    >Search</a
                  >
                  <a
                    class="fill-btn btn-style cursor-pointer"
                    v-on:click="resetFilter"
                    v-show="searchObj.keyword"
                    >Reset</a
                  >
                </div>
              </div>
            </div>

            <ul class="nav nav-pills mt-3" role="tablist">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-1"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  :class="{ active: currentTab == 'PENDING_FROM_ADMIN' }"
                  v-on:click="setStatus('PENDING_FROM_ADMIN')"
                >
                  <span class=""> Pending</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="true"
                  :class="{ active: currentTab == 'INPROCESS' }"
                  v-on:click="setStatus('INPROCESS')"
                >
                  <span class=""> InProcess</span></a
                >
              </li>

              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="true"
                  :class="{ active: currentTab == 'COMPLETED' }"
                  v-on:click="setStatus('COMPLETED')"
                >
                  <span class=""> Completed</span></a
                >
              </li>

              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="true"
                  :class="{ active: currentTab == 'REJECTED' }"
                  v-on:click="setStatus('REJECTED')"
                >
                  <span class=""> Rejected</span></a
                >
              </li>
            </ul>

            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Booking No.</th>
                          <th>Product</th>
                          <th>Seller</th>
                          <th>Buyer</th>
                          <th>Buy Rate</th>
                          <th>Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                          </td>
                          <td
                            v-on:click="$router.push('/view-booking/' + data.uuid)"
                            class="hover-td"
                          >
                            {{ data.booking_no }}
                          </td>
                          <td>{{ data.product.name }}</td>
                          <td>{{ data.seller.company_name }}</td>
                          <td>{{ data.buyer.company_name }}</td>
                          <td>{{ data.buy_rate }}</td>
                          <td>{{ data.ago_time }}</td>
                          <td>
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="$router.push('/view-booking/' + data.uuid)"
                              >View</a
                            >
                            <a
                              v-if="currentTab == 'PENDING_FROM_ADMIN'"
                              class="approve-btn btn-style cursor-pointer"
                              v-on:click="showPopup('ACCEPT', data)"
                              >Accept</a
                            >
                            <a
                              v-if="currentTab == 'PENDING_FROM_ADMIN'"
                              class="reject-btn btn-style cursor-pointer"
                              v-on:click="showPopup('REJECT', data)"
                              >Reject</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
    <TextModal ref="statusModal" class="modal-small">
      <div class="modal-header pt-0">
        <h5 class="modal-title" v-if="status == 'ACCEPT'">Accept</h5>
        <h5 class="modal-title" v-if="status == 'REJECT'">Reject</h5>
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <Form @submit="updateStatus">
        <div class="modal-body text-center">
          <h6 v-if="status == 'ACCEPT'">Are you sure you want to accept?</h6>
          <h6 v-if="status == 'REJECT'">Are you sure you want to reject?</h6>

          <div class="row" v-if="status == 'REJECT'">
            <div class="col-lg-12 col-sm-12 form-group row ml-2">
              <label> Reason <span class="text-danger">*</span> </label>
              <Field
                v-slot="{ field }"
                name="reason"
                v-model="reason"
                rules="required:reason"
                :validateOnInput="true"
                type="text"
                autocapitalize="false"
                class="form-control"
              >
                <textarea
                  v-bind="field"
                  placeholder="Reason"
                  class="form-control"
                  v-model="reason"
                ></textarea>
              </Field>
              <ErrorMessage name="reason" class="text-danger" />
            </div>
          </div>
          <div class="row" v-if="status == 'ACCEPT'">
            <div class="col-lg-12 col-sm-12 form-group row ml-2">
              <select class="form-control text-secondary" v-model="comission_type">
                <option value="">Select Comission Type</option>
                <option value="DEFAULT">Default</option>
                <option value="NEW">New</option>
              </select>
            </div>
            <div class="col-lg-6 col-sm-6" v-if="comission_type == 'NEW'">
              <div class="form-group">
                <label> Buyer Comission Percentage</label>
                <span class="text-danger">*</span>
                <Field
                  type="text"
                  name="buyer_comission_percentage"
                  class="form-control"
                  placeholder="Buyer Comission Percentage"
                  v-model="buyer_comission_percentage"
                  rules="required:buyer comission percentage|decimal"
                  :validateOnInput="true"
                />
                <ErrorMessage name="buyer_comission_percentage" class="text-danger" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-6" v-if="comission_type == 'NEW'">
              <div class="form-group">
                <label> Seller Comission Percentage</label>
                <span class="text-danger">*</span>
                <Field
                  type="text"
                  name="seller_comission_percentage"
                  class="form-control"
                  placeholder="Buyer Comission Percentage"
                  v-model="seller_comission_percentage"
                  rules="required:seller comission percentage|decimal"
                  :validateOnInput="true"
                />
                <ErrorMessage name="seller_comission_percentage" class="text-danger" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button class="fill-btn" id="status-button" style="min-width: 100px">
            Yes
          </button>
          <button
            type="button"
            class="btn cancel-btn"
            style="min-width: 100px"
            v-on:click="$refs.statusModal.closeModal"
          >
            No
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Datepicker from "@vuepic/vue-datepicker";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
export default {
  name: "bookingComponent",
  components: {
    ErrorComponent,
    Pagination,
    Datepicker,
    DeleteModal,
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: true,
      searchObj: {
        keyword: "",
        status: "",
      },
      offset: -1,
      list: [],
      currentTab: "PENDING_FROM_ADMIN",
      id: "",
      status: "",
      buyer_comission_percentage: "",
      seller_comission_percentage: "",
      comission_type: "",
      reason: "",
    };
  },
  mounted() {
    this.searchObj.status = this.currentTab;
    this.getList(0);
  },
  methods: {
    showPopup(status, data) {
      this.id = data.id;
      this.status = status;
      this.comission_type = "";
      this.seller_comission_percentage = "";
      this.buyer_comission_percentage = "";
      this.reason = "";
      this.$refs.statusModal.showModal();
    },
    setStatus(type) {
      this.currentTab = type;
      this.searchObj.status = this.currentTab;
      this.getList(0);
    },
    updateStatus() {
      var obj = {
        id: this.id,
        status: this.status,
      };
      if (this.status == "REJECT") {
        obj.reason = this.reason;
      }

      if (this.status == "ACCEPT") {
        obj.comission_type = this.comission_type;
        obj.buyer_comission_percentage = this.buyer_comission_percentage;
        obj.seller_comission_percentage = this.seller_comission_percentage;
      }

      this.$api
        .postAPI({
          _action: "update-booking-status",
          _body: obj,
          _buttonId: "status-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.statusModal.closeModal();
          this.id = "";
          this.status = "";
          this.comission_type = "";
          this.seller_comission_percentage = "";
          this.buyer_comission_percentage = "";
          this.reason = "";
          this.list = [];
          this.getList(0);
        })
        .catch(() => {});
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Booking",
        "Are you sure you want to delete Booking #" + data.booking_no,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/booking/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.date) {
        this.searchObj.from_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.searchObj.to_date = moment(this.date[1]).format("YYYY-MM-DD");
      }

      this.$api
        .getAPI({
          _action: "booking/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No bookings available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.searchObj.status = "";
      this.date = null;
      this.getList(0);
    },
  },
};
</script>

