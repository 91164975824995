<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Product</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <Form @submit="signup" class="form-style floating-form">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="floating-form-group">
                                  <label for="name">Name</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    class="form-control"
                                    :class="detail.name ? 'filled' : ''"
                                    v-model="detail.name"
                                    disabled="true"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="form-group">
                                  <label for="name">Select Type of Category</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    as="select"
                                    class="custom-select"
                                    v-model="detail.yarn_id"
                                    name="yarn"
                                    rules="required:Type of Category,true"
                                    @change="setName('YARN')"
                                  >
                                    <option value="">Select Type of Yarn</option>
                                    <option
                                      v-for="(data, index) in yarnList"
                                      :key="index"
                                      :value="data.id"
                                    >
                                      {{ data.name }}
                                    </option>
                                  </Field>
                                </div>
                                <ErrorMessage name="yarn" class="validation-msg" />
                              </div>
                            </div>

                            <div class="col-md-6" v-if="detail.yarn_id">
                              <div class="mb-3">
                                <div class="form-group">
                                  <label for="name"
                                    >Select {{ yarn_name }} Category Types</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    as="select"
                                    class="custom-select"
                                    v-model="detail.yarn_sub_id"
                                    name="sub_yarn"
                                    :rules="
                                      'required:' + yarn_name + ' Category Types,true'
                                    "
                                    @change="setName('SUB_YARN')"
                                    :disabled="!detail.yarn_id"
                                  >
                                    <option value="">
                                      Select {{ yarn_name }} Category Types
                                    </option>
                                    <option
                                      v-for="(data, index) in yarnSubList"
                                      :key="index"
                                      :value="data.id"
                                    >
                                      {{ data.name }}
                                    </option>
                                  </Field>
                                </div>
                                <ErrorMessage name="sub_yarn" class="validation-msg" />
                              </div>
                            </div>

                            <div class="col-md-6" v-if="detail.yarn_sub_id">
                              <div class="mb-3">
                                <div class="form-group">
                                  <label for="name">Select Category Value</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    as="select"
                                    class="custom-select"
                                    v-model="detail.yarn_value_id"
                                    name="yarn_value_id"
                                    rules="required:Category Value,true"
                                    :disabled="!detail.yarn_id"
                                  >
                                    <option value="">Select Category Value</option>
                                    <option
                                      v-for="(data, index) in valueList"
                                      :key="index"
                                      :value="data.id"
                                    >
                                      {{ data.value }}
                                    </option>
                                  </Field>
                                </div>
                                <ErrorMessage
                                  name="yarn_value_id"
                                  class="validation-msg"
                                />
                              </div>
                            </div>

                            <div class="col-md-6" v-if="detail.yarn_sub_id">
                              <div class="mb-3">
                                <div class="floating-form-group">
                                  <label for="min_order_quantity"
                                    >Min order quantity (Kg)</label
                                  ><span class="text-danger">*</span>
                                  <Field
                                    type="text"
                                    id="min_order_quantity"
                                    name="min_order_quantity"
                                    class="form-control"
                                    :class="detail.min_order_quantity ? 'filled' : ''"
                                    rules="required:min order quantity|decimal"
                                    v-model="detail.min_order_quantity"
                                  />
                                </div>
                              </div>
                              <ErrorMessage
                                name="min_order_quantity"
                                class="validation-msg"
                              />
                            </div>

                            <template v-if="detail.yarn_sub_id">
                              <template v-for="(item, i) in compositionlist" :key="i">
                                <div class="col-md-6">
                                  <div class="mb-3">
                                    <div class="floating-form-group">
                                      <label for="name">{{ item.name }}</label>
                                      <Field
                                        type="text"
                                        :id="'value_' + i"
                                        :name="'value_' + i"
                                        class="form-control"
                                        rules="credit_limit"
                                        :placeholder="item.name"
                                        :class="item.value ? 'filled' : ''"
                                        v-model="item.value"
                                        @keyup="setValue()"
                                      />
                                      <ErrorMessage
                                        :name="'value_' + i"
                                        class="validation-msg mt-2"
                                      />
                                    </div>
                                  </div>
                                </div> </template
                            ></template>

                            <div
                              class="col-lg-6 col-sm-4"
                              v-if="
                                detail.status == 'SELLER_REQUESTED' ||
                                detail.status == 'BUYER_REQUESTED'
                              "
                            >
                              <div class="form-group mt-4">
                                <div class="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch9"
                                    v-model="detail.is_status"
                                    true-value="Y"
                                    false-value="N"
                                  />
                                  <label class="custom-control-label" for="customSwitch9"
                                    >Status</label
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3 text-center">
                              <button
                                class="btn btn-brand-1"
                                id="signup-button"
                                :disabled="is_disabled"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import router from "../../router";
export default {
  name: "AddTrade",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      detail: {},
      id: this.$route.params.id,
      yarnList: [],
      yarnSubList: [],
      valueList: [],
      parent_id: 0,
      yarn_name: "",
      subYarn: {},
      compositionlist: [],
      is_disabled: true,
    };
  },
  mounted() {
    this.getYarnList("YARN");
    if (this.id) {
      this.getDetail();
    }
    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    setValue() {
      var value = 0;
      for (var i = 0; i < this.compositionlist.length; i++) {
        if (this.compositionlist[i].value && this.compositionlist[i].value > 0) {
          value += parseInt(this.compositionlist[i].value);
        }
      }
      console.log(value);
      if (value == 100) {
        this.is_disabled = false;
      } else {
        this.is_disabled = true;
      }
    },
    getCompositionList() {
      this.$api
        .getAPI({
          _action: "yarn-composition-list",
          _body: { yarn_id: this.detail.yarn_sub_id },
        })
        .then((res) => {
          this.compositionlist = res;
          for (var i = 0; i < this.compositionlist.length; i++) {
            if (this.id) {
              for (var j = 0; j < this.detail.list.length; j++) {
                if (
                  this.compositionlist[i].id == this.detail.list[j].composition_id &&
                  this.detail.list[j].value
                ) {
                  this.compositionlist[i].value = this.detail.list[j].value;
                }
              }
              this.setValue();
            } else {
              this.compositionlist[i].value = "";
            }
          }
        })
        .catch(() => {});
    },
    getYarnValuesList() {
      if (this.detail.yarn_sub_id) {
        this.$api
          .getAPI({
            _action: "yarn-values/" + this.detail.yarn_sub_id,
          })
          .then((res) => {
            this.valueList = res.list;
          })
          .catch(() => {});
      }
    },
    getYarnList(type) {
      this.$api
        .getAPI({
          _action: "yarns",
          _body: { parent_id: this.parent_id },
        })
        .then((res) => {
          if (type == "YARN") {
            this.yarnList = res.list;
          } else {
            this.yarnSubList = res.list;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/product/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res.result;
          this.subYarn = this.detail.sub_yarn;
          this.parent_id = this.detail.yarn_id;
          this.getYarnList("SUB_YARN");
          this.getYarnValuesList();
          this.getCompositionList();
        })
        .catch(() => {});
    },
    setName(type) {
      this.detail.name = "";
      if (type == "YARN") {
        this.detail.yarn_sub_id = "";
        this.subYarn = {};
      }
      for (var i = 0; i < this.yarnList.length; i++) {
        if (this.yarnList[i].id == this.detail.yarn_id) {
          this.detail.name = this.yarnList[i].name;
          this.yarn_name = this.yarnList[i].name;
        }
      }

      for (var j = 0; j < this.yarnSubList.length; j++) {
        if (this.yarnSubList[j].id == this.detail.yarn_sub_id) {
          this.detail.name = this.detail.name + " " + this.yarnSubList[j].name;
          if (type == "SUB_YARN") {
            this.subYarn = this.yarnSubList[j];
            this.getYarnValuesList();
            this.getCompositionList();
          }
        }
      }

      if (this.detail.yarn_id && type == "YARN") {
        this.parent_id = this.detail.yarn_id;
        this.getYarnList("SUB_YARN");
      }
    },
    signup() {
      var finalList = [];
      for (var i = 0; i < this.compositionlist.length; i++) {
        if (this.compositionlist[i].value && this.compositionlist[i].value > 0) {
          finalList.push(this.compositionlist[i]);
        }
      }
      this.detail.list = finalList;

      if (this.detail.is_status == "Y") {
        this.detail.status = "ACTIVE";
      }
      this.$api
        .postAPI({
          _action: "product-set",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>

