<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Notifications</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="card rounded-l border-0 shadow">
                <div v-for="(data, index) in list" :key="index" class="card-body border-bottom expert-list"
                  v-on:click="redirectTo(data)">
                  <div class="row">
                    <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                      <div class="pl-3">
                        <p class="font-medium mb-2 font-18 font-xs-16">
                          {{ data.title }}
                        </p>
                        <p class="
                            font-weight-light font-14
                            text-secondary
                            mb-1
                            font-xs-12
                          ">
                          {{ data.message }}
                        </p>
                        <p class="
                            font-weight-light font-14
                            text-secondary
                            mb-0
                            font-xs-12
                          ">
                          {{ data.created }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import moment from "moment";
export default {
  name: "MyNotification",
  components: {
    ErrorComponent, Pagination
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      searchObj: {
        offset: 0,
      },
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    redirectTo(detail) {
      this.unreadNotification(detail.id);
    },
    unreadNotification(id) {
      this.$api
        .postAPI({
          _action: "notification/read/" + id,
        })
        .then(() => { })
        .catch(() => { });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.list = [];

      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var j in res.list) {
            res.list[j].created_at = moment(res.list[j].created_at).format(
              "DD MMM YYYY | HH:mm A"
            );
            this.list.push(res.list[j]);
          }
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No notification available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>