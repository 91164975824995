<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Dealer</h4>
            </div>
          </div>
        </div>

        <ul class="nav nav-pills mt-3" role="tablist">
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link"
              id="tab-1"
              data-toggle="tab"
              href="#tab_1"
              role="tab"
              aria-controls="tab_1"
              aria-selected="true"
              :class="{ active: currentTab == 'BASIC_DETAILS' }"
              v-on:click="getDetail('BASIC_DETAILS')"
            >
              <span class="text-dark"> Basic Details</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link"
              id="tab-2"
              data-toggle="tab"
              href="#tab_2"
              role="tab"
              aria-controls="tab_2"
              aria-selected="true"
              :class="{ active: currentTab == 'EMPLOYEE' }"
              v-on:click="getDetail('EMPLOYEE')"
            >
              <span class="text-dark"> Companies</span></a
            >
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane mt-3"
            id="tab_1"
            role="tabpanel"
            aria-labelledby="tab-1"
            :class="{ active: currentTab == 'BASIC_DETAILS' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="row ml-2"><h1>Personal Info</h1></div>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 mb-3">
                      <div class="text-center mb-4">
                        <img
                          :src="
                            details.profile_image &&
                            details.profile_image != null
                              ? details.profile_image
                              : '/images/placeholder.gif'
                          "
                          id="preview"
                          class="img-thumbnail"
                          style="
                            border-radius: 100px;
                            width: 120px;
                            height: 120px;
                          "
                        />
                      </div>
                    </div>
                    <div class="col-lg-3"><label>Name</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.name }}</span>
                    </div>
                    <div class="col-lg-3"><label>Email</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.email }}</span>
                    </div>
                    <div class="col-lg-3"><label>Mobile Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.mobile_number }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>District</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.district">
                        {{ details.district.name }}</span
                      >
                      <span class="text-dark" v-else> -</span>
                    </div>
                    <div class="col-lg-3"><label>State</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.state">
                        {{ details.state.name }}</span
                      >
                      <span class="text-dark" v-else>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane mt-3"
            id="tab_2"
            role="tabpanel"
            aria-labelledby="tab-2"
            :class="{ active: currentTab == 'EMPLOYEE' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table
                        class="table table-striped table-bordered dataTable"
                        style="width: 100%"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th>Company Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody v-show="list.length > 0">
                          <tr v-for="(data, index) in list" :key="index">
                            <td>
                              {{ index + 1 }}
                            </td>
                            <td>{{ data.company.company_name }}</td>
                            <td>{{ data.designation }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-center" v-if="list.length == 0">
                        <div class="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
                          <h3 class="mb-4">
                            <strong>No companies available</strong>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
span {
  font-weight: 600;
}
</style>
<script>
export default {
  name: "EdiDealer",
  components: {},
  data() {
    return {
      uuid: this.$route.params.id,
      details: {
        name: "",
        email: "",
        mobile_number: "",
        district: {},
        state: {},
        company: {},
      },
      currentTab: "BASIC_DETAILS",
      list: [],
    };
  },
  mounted() {
    if (this.uuid) {
      this.getDetail("BASIC_DETAILS");
    }
  },
  methods: {
    getDetail(tab) {
      this.currentTab = tab;
      this.list = [];
      this.$api
        .getAPI({
          _action: "/get-user-detail/" + this.uuid,
        })
        .then((res) => {
          this.details = res.result;
          this.list = res.result.user_companies;
        })
        .catch(() => {});
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getDetail(this.currentTab);
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
}
</style>