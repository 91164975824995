<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Buyer</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-4 col-sm-4 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-3 form-group row mr-3">
                <select
                  class="form-control"
                  v-model="searchObj.status"
                  v-on:change="getList(0)"
                >
                  <option value="">ALL</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="SUSPENDED">INACTIVE</option>
                  <option value="PENDING">PENDING</option>
                </select>
              </div>
              <div class="col-lg-4 col-sm-4 form-group row  float-right">
                <div class="floating-form-group with-icon">
                  <Datepicker
                    v-model="date"
                    hideInputIcon
                    :clearable="false"
                    :enableTimePicker="false"
                    range="true"
                    format="yyyy-MM-dd"
                    placeholder="Select Date"
                    @update:modelValue="getList(0)"
                  ></Datepicker>
                </div>
                <a
                  class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword || searchObj.from_date || searchObj.status"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>User Name</th>
                          <th>Company(s)</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th>Date</th>
                          <th>status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.companies }}</td>
                          <td>{{ data.email }}</td>
                          <td>{{ data.mobile_number }}</td>
                          <td>
                            {{
                              $helperService.getFormatedDate(data.created_at)
                            }}
                          </td>
                          <td>
                            <span v-if="data.status == 'PENDING'">Pending</span
                            ><span v-if="data.status == 'ACTIVE'">Active</span
                            ><span v-if="data.status == 'SUSPENDED'"
                              >InActive</span
                            ><span v-if="data.status == 'REJECTED'"
                              >Rejected</span
                            >
                          </td>
                          <td>
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/view-company/' + data.id)
                              "
                              >View Company</a
                            >

                            <a
                              class="reject-btn inactive cursor-pointer"
                              v-on:click="showPopup('SUSPENDED', data)"
                              v-if="data.status == 'ACTIVE'"
                              >In Active</a
                            >

                            <a
                              class="reject-btn btn-style cursor-pointer"
                              v-on:click="showPopup('ACTIVE', data)"
                              v-if="data.status == 'SUSPENDED'"
                              >Active</a
                            >
                            <!-- <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="$router.push('/edit-buyer/' + data.uuid)"
                              >View</a
                            > -->
                            <a
                              class="reject-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content border-none">
      <div class="modal-header border-none" style="position:absolute; right:0; z-index: 99;">
        <button
          type="button"
          class="close"
          v-on:click="$refs.statusModal.closeModal"
        >
          <img src="/images/close.svg" width="15" style="position:absolute; top:0; right:20px;" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4" v-if="status == 'ACTIVE'">
          Active Buyer
        </h5>
        <h5 class="modal-title font-22 mb-4" v-if="status == 'REJECTED'">
          Reject Buyer
        </h5>

        <h5 class="modal-title font-22 mb-1" v-if="status == 'SUSPENDED'">
          In Active Buyer
        </h5>

        <p class="font-18" v-if="status == 'ACTIVE'">
          Are you sure <br />
          you want to active buyer?
        </p>
        <p class="font-18" v-if="status == 'REJECTED'">
          Are you sure <br />
          you want to reject buyer?
        </p>

        <p class="font-18" v-if="status == 'SUSPENDED'">
          Are you sure <br />
          you want to inactive buyer?
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pt-0 pb-0">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="fill-btn btn-style cursor-pointer mb-3"
              id="status-button"
              style="min-width: 100px"
              v-on:click="updateStatus()"
            >
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              class="btn cancel-btn cursor-pointer"
              
              v-on:click="$refs.statusModal.closeModal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  <SetCommodities ref="setCommodities" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal.vue";
import DeleteModal from "@/components/DeleteModal";
import SetCommodities from "../../components/SetCommodities.vue";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
export default {
  name: "BuyerComponent",
  components: {
    ErrorComponent,
    Pagination,
    TextModal,
    DeleteModal,
    Datepicker,
    SetCommodities,
  },
  data() {
    return {
      date: null,
      loader: true,
      searchObj: {
        keyword: "",
        status: "",
      },
      offset: -1,
      list: [],
      id: "",
      status: "",
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    showPopup(status, data) {
      this.id = data.id;
      this.status = status;
      this.$refs.statusModal.showModal();
    },
    setStatus(type) {
      this.currentTab = type;
      this.getList(0);
    },
    updateStatus() {
      var obj = {
        id: this.id,
        status: this.status,
      };
      this.$api
        .postAPI({
          _action: "update-user-status",
          _body: obj,
          _buttonId: "status-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.statusModal.closeModal();
          this.id = "";
          this.status = "";
          this.list = [];
          this.getList(0);
        })
        .catch(() => {});
    },
    showSetCommodities(data) {
      this.$refs.setCommodities.showModal(data);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Buyer",
        "Are you sure you want to delete " + data.company_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/delete-user/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.date) {
        this.searchObj.from_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.searchObj.to_date = moment(this.date[1]).format("YYYY-MM-DD");
      }
      this.searchObj.is_importer = "N";
      this.$api
        .getAPI({
          _action: "get-user/BUYER",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No buyers available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.searchObj.status = "";
      this.searchObj.date =null;
      this.date = null;
      this.getList(0);
    },
  },
};
</script>

