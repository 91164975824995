<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Dealer</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/placeholder.gif'
                              "
                              id="preview"
                              class="img-thumbnail"
                              style="border-radius: 100px; width: 120px; height: 120px"
                            /><br />
                            <div id="msg"></div>
                            <div id="image-form" style="margin: 0 auto; width: 90px">
                              <Field
                                type="file"
                                name="img[]"
                                class="file"
                                id="pickFile"
                                @change="selectedFile($event)"
                                accept="image/*"
                                style="display: none"
                              />
                              <div class="input-group my-1">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="img-text"
                                  disabled
                                  placeholder="Upload File"
                                  id="file"
                                  style="display: none"
                                />
                                <div class="changePic" style="bottom: -25px">
                                  <label for="pickFile"> Change</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="first_name"
                              class="form-control"
                              placeholder="First Name"
                              v-model="detail.first_name"
                              rules="required:first name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="first_name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="Last Name"
                              v-model="detail.last_name"
                              rules="required:last name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="last_name" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Email"
                              class="form-control"
                              v-model="detail.email"
                              rules="required:email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile Number</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Mobile Mumber"
                              class="form-control"
                              v-model="detail.mobile_number"
                              rules="required:mobile number|phone"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="phone" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.state_id"
                              name="state"
                              rules="required:state,true"
                              @change="getDistrictList()"
                            >
                              <option value="">Select State</option>
                              <option
                                v-for="(data, index) in stateList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                            <ErrorMessage name="state" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.district_id"
                              name="district"
                              rules="required:district,true"
                              :disabled="!detail.state_id"
                            >
                              <option value="">Select District</option>
                              <option
                                v-for="(data, index) in districtList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                            <ErrorMessage name="district" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <Multiselect
                              mode="tags"
                              trackBy="name"
                              label="name"
                              valueProp="id"
                              v-model="products"
                              placeholder="Select Product"
                              :options="productList"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.status"
                              rules="required:status,true"
                              name="status"
                            >
                              <option value="">Select Status</option>
                              <option value="ACTIVE">Active</option>
                              <option value="SUSPENDED">Suspended</option>
                            </Field>
                            <ErrorMessage name="status" class="text-danger" />
                          </div>
                        </div>

                        <template v-if="!uuid">
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Password</label>
                              <span class="text-danger">*</span>
                              <Field
                                :type="newPasswordFieldType"
                                id="password"
                                name="password"
                                placeholder="Password"
                                class="form-control"
                                v-model="detail.password"
                                v-on:keydown.space="$event.preventDefault()"
                                rules="required:password|minLength:8|password"
                                :validateOnInput="true"
                              />
                              <a class="psw-icon" v-on:click="switchVisibilityNew"
                                ><img :src="imageNew" width="20"
                              /></a>
                              <ErrorMessage name="password" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Confirm Password</label>
                              <span class="text-danger">*</span>
                              <Field
                                :type="passwordFieldType"
                                id="conf_password"
                                name="confirm_password"
                                class="form-control"
                                placeholder="Confirm Password"
                                v-on:keydown.space="$event.preventDefault()"
                                rules="required:confirm password|confirmed:@password"
                                :validateOnInput="true"
                              />
                              <a class="psw-icon" v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                              <ErrorMessage name="confirm_password" class="text-danger" />
                            </div></div
                        ></template>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
export default {
  name: "AddDealer",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      uuid: this.$route.params.id,
      file: undefined,
      stateList: [],
      districtList: [],
      productList: [],
      detail: {
        user_type: "BROKER",
      },
      products: [],
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
    };
  },
  mounted() {
    this.getStateList();
    this.getProductList();
  },
  methods: {
    searchCompanyName(e) {
      console.log(e);
    },
    getProductList() {
      this.$api
        .getAPI({
          _action: "product/list",
        })
        .then((res) => {
          this.productList = res.list;
        })
        .catch(() => {});
    },
    getStateList() {
      this.$api
        .getAPI({
          _action: "states",
        })
        .then((res) => {
          this.stateList = res.list;
          if (this.uuid) {
            this.getDetail();
          }
        })
        .catch(() => {});
    },
    getDistrictList() {
      this.$api
        .getAPI({
          _action: "districts/" + this.detail.state_id,
        })
        .then((res) => {
          this.districtList = res.list;
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/broker/detail/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.result;
          if (this.detail.state_id) {
            this.getDistrictList();
          }
          if (this.detail.products) {
            for (var i = 0; i < this.detail.products.length; i++) {
              this.products.push(this.detail.products[i].product_id);
            }
          }
        })
        .catch(() => {});
    },
    save() {
      if (this.products.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select products",
        });
        return false;
      }
      this.detail.uuid = this.uuid;
      this.detail.products = this.products;
      this.$api
        .postAPI({
          _action: "/broker-set",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            if (this.file) {
              this.uploadImage(res.id);
              return;
            }
            this.$router.go(-1);
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(uuid) {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/broker-image",
          _key: "image",
          _file: this.file,
          _body: {
            uuid: uuid,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.psw-icon {
  position: absolute;
  right: 30px;
  top: 40px;
}
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>