<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Company</h4>
            </div>
          </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane mt-3"
            id="tab_1"
            role="tabpanel"
            aria-labelledby="tab-1"
            :class="{ active: currentTab == 'BASIC_DETAILS' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table
                        class="table table-striped table-bordered dataTable"
                        style="width: 100%"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Sr.No.</th>
                            <th>Company Name</th>
                            <th>GST Number</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-show="!loader">
                          <tr v-for="(data, index) in list" :key="index">
                            <td>
                              {{
                                ($refs.listPagination.currentPage - 1) * 10 + index + 1
                              }}
                            </td>
                            <td>{{ data.company_name }}</td>
                            <td>{{ data.gst_number }}</td>
                            <td>{{ data.status }}</td>
                            <td>
                              <a
                                class="approve-btn btn-style cursor-pointer"
                                v-on:click="showPopup('APPROVED', data)"
                                v-if="data.status == 'PENDING'"
                                >Accept</a
                              >
                              <a
                                class="reject-btn btn-style cursor-pointer"
                                v-on:click="showPopup('REJECTED', data)"
                                v-if="data.status == 'PENDING'"
                                >Reject</a
                              >
                              <a
                                v-if="data.owner.user_type == 'SELLER'"
                                class="fill-btn btn-style cursor-pointer"
                                v-on:click="$router.push('/edit-seller/' + data.id)"
                                >View</a
                              >
                              <a
                                v-if="data.owner.user_type == 'BUYER'"
                                class="fill-btn btn-style cursor-pointer"
                                v-on:click="$router.push('/edit-buyer/' + data.id)"
                                >View</a
                              >
                              <a
                                v-if="
                                  data.status == 'APPROVED' ||
                                  data.status == 'REJECTED' ||
                                  data.status == 'PENDING'
                                "
                                class="reject-btn btn-style cursor-pointer"
                                v-on:click="showDeletePopup(data)"
                                >Delete</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <!-- <div class="row ml-2 mt-2">
                    <h1>Company Info</h1>
                  </div>
                  <div class="row">
                    <div class="col-lg-3"><label>Name</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.company.company_name }}</span>
                    </div>
                    <div class="col-lg-3"><label>GST Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.company.gst_number }}</span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane mt-3"
            id="tab_2"
            role="tabpanel"
            aria-labelledby="tab-2"
            :class="{ active: currentTab == 'EMPLOYEE' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table
                        class="table table-striped table-bordered dataTable"
                        style="width: 100%"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                          </tr>
                        </thead>
                        <tbody v-show="!loader">
                          <tr v-for="(data, index) in list" :key="index">
                            <td>
                              {{
                                ($refs.listPagination.currentPage - 1) * 10 + index + 1
                              }}
                            </td>
                            <td>{{ data.first_name }}</td>
                            <td>{{ data.last_name }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.mobile_number }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-0 mb-4">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageChange"
                          @items-per-page-change="itemsPerPageChange"
                          ref="listPagination"
                        />
                      </div>
                    </div>
                  </div>
                  <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content border-0">
      <div class="">
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4" v-if="companyStatus.status == 'APPROVED'">
          Accept
          {{
            companyStatus.user_type == "SELLER"
              ? companyStatus.is_importer == "Y"
                ? "Importer"
                : "Seller"
              : companyStatus.user_type == 'BROKER' ? 'Dealer' : $helperService.getTitleCase(companyStatus.user_type)
          }}
        </h5>
        <h5 class="modal-title font-22 mb-4" v-if="companyStatus.status == 'REJECTED'">
          Reject
          {{
            companyStatus.user_type == "SELLER"
              ? companyStatus.is_importer == "Y"
                ? "Importer"
                : "Seller"
              : companyStatus.user_type == 'BROKER' ? 'Dealer' :  $helperService.getTitleCase(companyStatus.user_type)
          }}
        </h5>

        <h5 class="modal-title font-22 mb-4" v-if="status == 'SUSPENDED'">
          InActive
          {{
            companyStatus.user_type == "SELLER"
              ? companyStatus.is_importer == "Y"
                ? "Importer"
                : "Seller"
              : companyStatus.user_type == 'BROKER' ? 'Dealer' : $helperService.getTitleCase(companyStatus.user_type)
          }}
        </h5>

        <p class="font-18" v-if="companyStatus.status == 'APPROVED'">
          Are you sure <br />
          you want to accept
          {{
            companyStatus.user_type == "SELLER"
              ? companyStatus.is_importer == "Y"
                ? "Importer"
                : "Seller"
              : companyStatus.user_type == 'BROKER' ? 'Dealer' : $helperService.getTitleCase(companyStatus.user_type)
          }}
          ?
        </p>
        <p class="font-18" v-if="companyStatus.status == 'REJECTED'">
          Are you sure <br />
          you want to reject
          {{
            companyStatus.user_type == "SELLER"
              ? companyStatus.is_importer == "Y"
                ? "Importer"
                : "Seller"
              : companyStatus.user_type == 'BROKER' ? 'Dealer' : $helperService.getTitleCase(companyStatus.user_type)
          }}
          ?
        </p>

        <p class="font-18" v-if="status == 'SUSPENDED'">
          Are you sure <br />
          you want to inactive ?
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="fill-btn btn-style cursor-pointer mb-3"
              id="status-button"
              style="min-width: 115px"
              v-on:click="changeStatus()"
            >
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              class="btn cancel-btn"
              style="min-width: 100px"
              v-on:click="$refs.statusModal.closeModal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal.vue";
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "EditCompanyDetails",
  components: { ErrorComponent, Pagination, TextModal, DeleteModal },
  data() {
    return {
      uuid: this.$route.params.id,
      details: {
        name: "",
        email: "",
        mobile_number: "",
        district: {},
        state: {},
        company: {},
      },
      currentTab: "BASIC_DETAILS",
      loader: true,
      companyObj: {
        keyword: "",
        user_id: "",
      },
      searchObj: {
        keyword: "",
      },
      companyStatus: {
        id: "",
        status: "",
      },
      user_type: "",
      offset: -1,
      list: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.companyObj.user_id = this.$route.params.id;
      this.getCompany(0);
    }
  },
  methods: {
    showPopup(status, data) {
      this.companyStatus.id = data.id;
      this.companyStatus.status = status;
      this.companyStatus.user_type = data.owner.user_type;
      this.companyStatus.is_importer = data.is_importer;
      this.$refs.statusModal.showModal();
    },
    pageChange(offset) {
      this.getList(offset - 1);
      this.getCompany(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
      this.getCompany(0);
    },
    getList(page) {
      this.currentTab = "EMPLOYEE";
      // this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.parent_id = this.details.id;
      // this.searchObj.type = "BUYER";

      this.$api
        .getAPI({
          _action: "get-employee",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No employees available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete",
        "Are you sure you want to delete " + data.company_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "delete/company/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getCompany(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    changeStatus() {
      this.$api
        .putAPI({
          _action: "company/" + this.companyStatus.id,
          _body: this.companyStatus,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.statusModal.closeModal();
          this.getCompany(0);
        })
        .catch(() => {});
    },
    getCompany(page) {
      // this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.companyObj.offset = page;
      // this.companyObj.user_id = this.details.id;
      // this.companyObj.type = "BUYER";

      this.$api
        .getAPI({
          _action: "companies",
          _body: this.companyObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No employees available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
}
</style>