<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.go(-1)"><span class="font-24 mr-2"><img
                    src="/images/back-arrow.svg" width="18" /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Seller</h4>
            </div>
          </div>
        </div>

        <ul class="nav nav-pills mt-3 mx-4" role="tablist">
          <li class="nav-item cursor-pointer">
            <a class="nav-link" id="tab-1" data-toggle="tab" href="#tab_1" role="tab" aria-controls="tab_1"
              aria-selected="true" :class="{ active: currentTab == 'BASIC_DETAILS' }" v-on:click="getDetail()">
              <span class=""> Basic Details</span></a>
          </li>
          <li class="nav-item cursor-pointer">
            <a class="nav-link" id="tab-2" data-toggle="tab" href="#tab_2" role="tab" aria-controls="tab_2"
              aria-selected="true" :class="{ active: currentTab == 'EMPLOYEE' }" v-on:click="getList(0)">
              <span class=""> Employees</span></a>
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane mt-3" id="tab_1" role="tabpanel" aria-labelledby="tab-1"
            :class="{ active: currentTab == 'BASIC_DETAILS' }">
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="row ml-2">
                    <h5 class="m-0">Company Info</h5>
                  </div>
                  <hr />
                  <br />
                  <div class="row">
                    <div class="col-lg-3"><label>Name</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.company_name }}</span>
                    </div>
                    <div class="col-lg-2"><label>GST Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.gst_number }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <label>Company Address</label>
                    </div>
                    <div class="col-lg-9">
                      <div class="" v-if="details.billing_address">
                        <span class="text-dark">
                          {{ details.billing_address.address }},
                          {{ details.billing_address.district.name }} (
                          {{ details.billing_address.state.name }} )</span>
                      </div>
                      <div class="" v-if="details.delivery_address">
                        <span class="text-dark">
                          {{ details.delivery_address.address }},
                          {{ details.delivery_address.district.name }} (
                          {{ details.delivery_address.state.name }} )</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane mt-3" id="tab_2" role="tabpanel" aria-labelledby="tab-2"
            :class="{ active: currentTab == 'EMPLOYEE' }">
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                        aria-describedby="example_info">
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                          </tr>
                        </thead>
                        <tbody v-show="!loader">
                          <tr v-for="(data, index) in list" :key="index">
                            <td>
                              {{
                                ($refs.listPagination.currentPage - 1) * 10 +
                                index +
                                1
                              }}
                            </td>
                            <td>{{ data.first_name }}</td>
                            <td>{{ data.last_name }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.mobile_number }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                    ref="listPagination" />
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
span {
  font-weight: 600;
}
</style>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "EditSeller",
  components: { ErrorComponent, Pagination },
  data() {
    return {
      uuid: this.$route.params.id,
      company_id: this.$route.params.id,
      details: {
        company_name: "",
        get_number: "",
      },
      productList: [],
      setData: {
        user_type: "",
      },
      products: [],
      currentTab: "BASIC_DETAILS",
      loader: true,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.currentTab = "BASIC_DETAILS";
      this.$api
        .getAPI({
          _action: "companies/" + this.$route.params.id,
        })
        .then((res) => {
          this.details = res;
          this.setData.credit_limit = res.credit_limit;
        })
        .catch(() => { });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.currentTab = "EMPLOYEE";
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.parent_id = this.details.id;
      this.searchObj.type = "SELLER";

      this.$api
        .getAPI({
          _action: "get-employee",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No employees available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>
