import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api from './services/WebService'
import Notifications from '@kyvg/vue3-notification'
import helperService from './services/HelperService';
import "./services/ValidationRules";
import global from './services/global'


const app = createApp(App);
app.use(router)
app.use(Notifications)
app.mount('#app')

app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$global = global;