<template>
  <div>
    <div class="sidebar-wrapper" style="overflow-y: scroll">
      <div class="sidebar-header">
        <div class="">
          <img src="/images/yarnmart-Logo.svg" width="220" />
        </div>
      </div>
      <ul class="metismenu" id="menu">
        <li>
          <a id="dashboard" :class="{ active: pageName == 'dashboard' }" style="cursor: pointer"
            v-on:click="$router.push('/dashboard')">
            <!-- <img src="/img/dashboard.png" /> -->
            <i class="fa fa-tachometer" aria-hidden="true"></i>

            <span class="menu-title">Dashboard</span>
          </a>
        </li>

        <li>
          <a id="product" :class="{ active: pageName == 'product' }" style="cursor: pointer"
            v-on:click="$router.push('/product')">
            <!-- <img src="/img/dashboard.png" /> -->
            <i class="fa fa-cube" aria-hidden="true"></i>

            <span class="menu-title">Manage Product</span>
          </a>
        </li>

        <li>
          <a id="requested-product" :class="{ active: pageName == 'requested-product' }" style="cursor: pointer"
            v-on:click="$router.push('/requested-product')">
            <!-- <img src="/img/dashboard.png" /> -->
            <i class="fa fa-cube" aria-hidden="true"></i>

            <span class="menu-title">Requested Product</span>
          </a>
        </li>


        <li>
          <a id="category" :class="{ active: pageName == 'category' }" style="cursor: pointer" v-on:click="$router.push('/category')">
            <!-- <i class="fa fa-sitemap" aria-hidden="true"></i> -->
            
            <i class="fa fa-tasks" aria-hidden="true"></i>
            <span class="menu-title">Manage Category</span>
          </a>
        </li>

        <li>
          <a id="composition" :class="{ active: pageName == 'composition' }" style="cursor: pointer" v-on:click="$router.push('/composition')">
            <!-- <i class="fa fa-sitemap" aria-hidden="true"></i> -->
            
            <i class="fa fa-tasks" aria-hidden="true"></i>
            <span class="menu-title">Manage Composition</span>
          </a>
        </li>

        <li>
          <a id="seller" :class="{ active: pageName == 'seller' }" style="cursor: pointer"
            v-on:click="$router.push('/seller')">
            <i class="fa fa-shopping-cart"></i>
            <div class="menu-title">Seller</div>
          </a>
        </li>
        <li>
          <a id="buyer" :class="{ active: pageName == 'buyer' }" style="cursor: pointer"
            v-on:click="$router.push('/buyer')">
            <i class="fa fa-shopping-cart"></i>
            <div class="menu-title">Buyer</div>
          </a>
        </li>
        <li>
          <a id="dealer" :class="{ active: pageName == 'dealer' }" style="cursor: pointer"
            v-on:click="$router.push('/dealer')">
            <i class="fa fa-exchange" aria-hidden="true"></i>
            <div class="menu-title">Dealer</div>
          </a>
        </li>

        <li>
          <a id="importer" :class="{ active: pageName == 'importer' }" style="cursor: pointer"
            v-on:click="$router.push('/importer')">
            <i class="fa fa-shopping-cart"></i>
            <div class="menu-title">Importer</div>
          </a>
        </li>
        
        <li>
          <a id="comission" :class="{ active: pageName == 'comission' }" style="cursor: pointer"
            v-on:click="$router.push('/comission')">
            <i class="fa fa-tasks"></i>
            <span class="menu-title">Manage Comission</span>
          </a>
        </li>


        <li>
          <a id="booking" :class="{ active: pageName == 'booking' }" style="cursor: pointer"
            v-on:click="$router.push('/booking')">
            <i class="fa fa-book" aria-hidden="true"></i>
            <span class="menu-title">Manage Booking</span>
          </a>
        </li>

        <li>
          <a
            id="service"
            :class="{ active: pageName == 'service' }"
            style="cursor: pointer"
            v-on:click="$router.push('/service')"
          >
            <i class="fa fa-wrench" aria-hidden="true"></i>
            <span class="menu-title">Manage Pay Service</span>
          </a>
        </li>
        
        <li>
          <a id="setting" :class="{ active: pageName == 'setting' }" style="cursor: pointer"
            v-on:click="$router.push('/setting')">
            <i class="fa fa-cog" aria-hidden="true"></i>
            <span class="menu-title">Manage Setting</span>
          </a>
        </li>

        <li>
          <a id="package" :class="{ active: pageName == 'package' }" style="cursor: pointer"
            v-on:click="$router.push('/package')">
            <!-- <img src="/img/dashboard.png" /> -->
            <i class="fa fa-usd"></i>
            <span class="menu-title">Manage Package</span>
          </a>
        </li>
        
        <li>
          <a id="support" :class="{ active: pageName == 'support' }" style="cursor: pointer"
            v-on:click="$router.push('/support')">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            <span class="menu-title">Manage Queries</span>
          </a>
        </li>

      </ul>
    </div>
    <header class="top-header">
      <nav class="navbar navbar-expand">
        <div class="left-topbar d-flex align-items-center">
          <a href="javascript:;" class="toggle-btn">
            <i class="bx bx-menu"></i>
          </a>
        </div>
        <div class="right-topbar ml-auto">
          <div class="pr-4 d-flex justify-content-end align-items-center">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropdown-user-profile">
                <a class="nav-link dropdown-toggle-nocaret pr-0" href="javascript:;" data-toggle="dropdown">
                  <div class="media user-box align-items-center">
                    <div class="media-body user-info">
                      <p class="user-name mb-0" id="loginuser-name">
                        {{ details.name }}
                      </p>
                    </div>
                    <img :src="details.profile_image && details.profile_image != 'null'
                      ? details.profile_image
                      : '/images/placeholder.gif'
                      " class="user-img" id="loginuser-image" />
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <router-link to="/my-profile">
                    <a class="dropdown-item">
                      <i class="bx bx-user"></i>
                      <span>Profile</span>
                    </a>
                  </router-link>
                  <div class="dropdown-divider mb-0"></div>
                  <a class="dropdown-item" style="cursor: pointer" v-on:click="logout">
                    <i class="bx bx-power-off"></i>
                    <span>Logout</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import api from "../services/WebService";
import moment from "moment";
export default {
  name: "MenuComponent",
  data() {
    return {
      pageName: "",
      details: {},
      isShow: false,
      searchObj: {
        offset: 0,
      },
      notificationList: [],
      count: "",
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getLoginUserDetail();

    // this.getNotification(0);
    setInterval(() => {
      clearInterval(0);
      // this.getUnreadCount();
    }, 100000);
  },
  methods: {
    getNotification() {
      var offset = 0;
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = 0;
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.length = res.list.length;
          this.count = res.unread_count;
          if (res.list.length <= 2) {
            for (var j in res.list) {
              res.list[j].created_at = moment(res.list[j].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[j]);
            }
          } else {
            for (var i = 0; i < 3; i++) {
              res.list[i].created_at = moment(res.list[i].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[i]);
            }
          }
        })
        .catch(() => { });
    },
    getUnreadCount() {
      this.$api
        .getAPI({
          _action: "notification/count",
        })
        .then((res) => {
          this.count = res.unread_count;
        })
        .catch(() => { });
    },
    redirectTo(detail) {
      this.unreadNotification(detail.id);
    },
    unreadNotification(id) {
      this.$api
        .postAPI({
          _action: "notification/read/" + id,
        })
        .then(() => {
          this.getNotification(0);
          this.getUnreadCount();
        })
        .catch(() => { });
    },
    isShowList() {
      this.isShow = true;
    },
    logout() {
      let subscription = localStorage.getItem("subscription");
      console.log("subscription " + subscription);
      if (subscription) {
        this.$api
          .postAPI({
            _action: "unregister-device",
            _body: {
              token: subscription,
              token_type: "WEB",
            },
          })
          .then(() => {
            localStorage.clear();
            this.$router.push("/login");
          })
          .catch((e) => {
            this.$noty.error("" + e.message, {
              timeout: 2000,
              layout: "topCenter",
              theme: "semanticui",
            });
          });
      } else {
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    getLoginUserDetail() {
      api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (document.getElementById("loginuser-image")) {
            document.getElementById("loginuser-image").src = res.result.profile_image;
          }
          this.details = res.result;
        })
        .catch(() => { });
    },
  },
};
const accSingleTriggers = document.querySelectorAll(".js-acc-single-trigger");
accSingleTriggers.forEach((trigger) =>
  trigger.addEventListener("click", toggleAccordion)
);

function toggleAccordion() {
  const items = document.querySelectorAll(".js-acc-item");
  const thisItem = this.parentNode;
  items.forEach((item) => {
    if (thisItem == item) {
      thisItem.classList.toggle("is-open");
      return;
    }
    item.classList.remove("is-open");
  });
}
</script>

