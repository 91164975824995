<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Order</h4>
            </div>
          </div>
        </div>

        <ul class="nav nav-pills mt-3" role="tablist">
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link"
              id="tab-1"
              data-toggle="tab"
              href="#tab_1"
              role="tab"
              aria-controls="tab_1"
              aria-selected="true"
              :class="{ active: currentTab == 'BASIC_DETAILS' }"
              v-on:click="getDetail('BASIC_DETAILS')"
            >
              <span class="text-dark"> Order Detail</span></a
            >
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane mt-3"
            id="tab_1"
            role="tabpanel"
            aria-labelledby="tab-1"
            :class="{ active: currentTab == 'BASIC_DETAILS' }"
          >
            <div class="col-lg-10 col-sm-10 float-none">
              <div class="card border mb-0 radius-15">
                <div class="card-body">
                  <div class="row ml-2"><h3>Order Info</h3></div>
                  <div class="row">
                    <div class="col-lg-3"><label>Order No.</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.order_no }}</span>
                    </div>
                    <div class="col-lg-3"><label>Booking No.</label></div>
                    <div class="col-lg-3">
                      <span
                        class="text-dark hover-sapn cursor-pointer"
                        v-on:click="
                          $router.push('/view-booking/' + details.booking.uuid)
                        "
                      >
                        {{ details.booking.booking_no }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Seller</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.booking.seller.company_name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Buyer</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.booking.buyer.company_name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Product</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.booking.product.name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Quantity</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.quantity }}
                        (Kg)</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Dispatch At</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{
                          $helperService.getFormatedDate(details.dispatch_at)
                        }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>UTR Number</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.utr_number">
                        {{ details.utr_number }}</span
                      >
                      <span class="text-dark" v-else> -</span>
                    </div>

                    <div class="col-lg-3">
                      <label>Payment status</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.payment_status }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Status</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.status }}</span>
                    </div>

                    <div class="col-lg-3"><label>Invoice Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.invoice_no">
                        {{ details.invoice_no }}</span
                      >
                      <span class="text-dark" v-else> - </span>
                    </div>
                    <div class="col-lg-3">
                      <label>Invoice Amount</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.invoice_amount">
                        {{ details.invoice_amount }}</span
                      >
                      <span class="text-dark" v-else> 0</span>
                    </div>
                    <div class="col-lg-3">
                      <label>Paid Amount</label>
                    </div>
                    <div class="col-lg-3">
                      <span class="text-dark" v-if="details.paid_amount">
                        {{ details.paid_amount }}</span
                      >
                      <span class="text-dark" v-else> 0</span>
                    </div>

                    <div class="col-lg-3"><label>Driver Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.driver_number }}</span
                      >
                    </div>

                    <div class="col-lg-3"><label>L.R. Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark"> {{ details.lr_number }}</span>
                    </div>
                    <div class="col-lg-3"><label>Transporter Name</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.transporter_name }}</span
                      >
                    </div>
                    <div class="col-lg-3"><label>Vehicle Number</label></div>
                    <div class="col-lg-3">
                      <span class="text-dark">
                        {{ details.vehicle_number }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewOrder",
  components: {},
  data() {
    return {
      uuid: this.$route.params.id,
      details: {
        order_no: "",
        booking: {
          trade: {},
          dealer: {},
          product: {},
          buyer: {},
          seller: {},
        },
      },
      currentTab: "BASIC_DETAILS",
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;
    if (this.uuid) {
      this.getDetail("BASIC_DETAILS");
    }
  },
  methods: {
    getDetail(tab) {
      this.currentTab = tab;
      this.list = [];
      this.$api
        .getAPI({
          _action: "/order/" + this.uuid,
        })
        .then((res) => {
          this.details = res.info;
          if (!this.details.video) {
            this.details.video = "";
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
}
</style>